import * as React from 'react';
import Box from '@mui/material/Box';
import NavBar from '../../components/navbar';

export default function BasePage({ page }) {
    return (
        <Box sx={{ flexGrow: 1 }}>
            <NavBar />
            {page}
        </Box>
    )
}
