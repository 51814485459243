import {
    Typography, Stack,
    Card, Box,
    CardActionArea,
} from '@mui/material'
import { useNavigate, useHistory } from 'react-router-dom'

const CategoryListAdapter = ({ categoryItem }) => {

    const navigate = useNavigate();


    return (
        <Card variant="outlined" sx={{ bgcolor: "transparent" }}>
            <CardActionArea onClick={() => navigate(`${categoryItem.link}`)}>
                <Stack direction="row" sx={{ alignItems: "center" }}>
                    <Box
                        component="img"
                        height="40px"
                        width="40px"
                        src={categoryItem.image}
                        alt={categoryItem.title}
                        sx={{
                            margin: "8px",
                        }}
                    />
                    <Typography sx={{ fontSize: 16, fontWeight: 500, WebkitLineClamp: "2", overflow: "hidden", textOverflow: "ellipsis" }}>{categoryItem.title}</Typography>
                </Stack>
            </CardActionArea>
        </Card>
    )
}

export default CategoryListAdapter