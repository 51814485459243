import { Box, CircularProgress } from '@mui/material'
import React from 'react'

const AdPageSkeleton = () => {
    return (
        <Box sx={{ display: "flex", flexGrow: 1, alignItems: "center", justifyContent: "center", height: "100vh" }}>
            <CircularProgress />
        </Box>
    )
}

export default AdPageSkeleton