import AppUI from './config/theme';
import BasePage from './pages/base_page/base_page';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from './pages/home_page/home_page';
import AdPage from './pages/ad_page/ad_page';
import { useEffect } from 'react';
import CategoryDetailsPage from './pages/category_details_page/category_details_page';

function App() {

  useEffect(() => {
    window.history.scrollRestoration = 'manual'
  }, []);


  return (
    <AppUI>
      <Router>
        <Routes>
          <Route path='/' element={<BasePage page={<HomePage />} />} />
          <Route path='/post' element={<BasePage page={<AdPage />} />} />
          <Route path='/mobile-phones' element={<BasePage page={<CategoryDetailsPage
            linkName="Buy Second hand Mobile phones"
            categoryIdLink="mobiles"
            subCategoryIdLink="mobile_phones" />} />} />
          <Route path='/bikes' element={<BasePage page={<CategoryDetailsPage linkName="Buy Second hand Bikes"
            categoryIdLink="vehicles"
            subCategoryIdLink="bikes" />} />} />
          <Route path='/cars' element={<BasePage page={<CategoryDetailsPage linkName="Buy Second hand Cars"
            categoryIdLink="vehicles"
            subCategoryIdLink="cars" />} />} />
          {/* <Route path='/houses' element={<BasePage page={<CategoryDetailsPage 
          linkName="Buy or Rent Houses & Apartments" />} />} /> */}
          <Route path='/laptops' element={<BasePage page={<CategoryDetailsPage
            linkName="Buy Second hand Laptops"
            categoryIdLink="computers"
            subCategoryIdLink="laptops" />} />} />
          <Route path='/tv' element={<BasePage page={<CategoryDetailsPage
            linkName="Buy Second hand TV"
            categoryIdLink="homeappliances"
            subCategoryIdLink="tv" />} />} />
          <Route path='/earphones' element={<BasePage page={<CategoryDetailsPage
            linkName="Buy Headsets, Earphones"
            categoryIdLink="mobiles"
            subCategoryIdLink="earphones" />} />} />
          <Route path='/watches' element={<BasePage page={<CategoryDetailsPage
            linkName="Buy Smart Watch, Fitbit and other wearbles"
            categoryIdLink="fashion"
            subCategoryIdLink="watches" />} />} />
          <Route path='/dogs' element={<BasePage page={<CategoryDetailsPage
            linkName="Buy Cute puppies & Dogs"
            categoryIdLink="pets"
            subCategoryIdLink="dogs" />} />} />
          {/* <Route path='/filter' element={<BasePage page={<CategoryDetailsPage />} />} /> */}
        </Routes>
      </Router>
    </AppUI>
  );
}

export default App;
