import IconButton from '@mui/material/IconButton';
import './filledbutton.css';
import 'material-symbols';

const Icon = ({ icon, iconType, iconSize }) => {
    return <span className="material-symbols-outlined" style={{ fontSize: iconSize }}>{icon}</span>
}

const MaterialIconButton = ({ sx, icon, iconType, iconSize, onClick }) => {
    return (
        <IconButton sx={{ bgcolor: "background.text.primary", ...sx }} color='inherit' onClick={onClick}><Icon icon={icon} iconSize={iconSize} /></IconButton>
    )
}

export default MaterialIconButton