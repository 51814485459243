import {
    Typography, Box,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { currencyFormat } from '../../config/apputilty';


function AdAdapter({ adItem }) {

    const navigate = useNavigate();

    const gotoAdDetailsPage = () => { window.open(`/post?pid=${adItem.id}`, "_blank") };

    return (
        <Box>
            <Card sx={{ borderRadius: 0, padding: 0, }}>
                <CardActionArea onClick={gotoAdDetailsPage}>
                    <CardMedia
                        component="img"
                        height="300px"
                        image={adItem.images[0]}
                        alt={adItem.title + "Cover Image"}
                        sx={{
                            bgcolor: "background.card.foreground",
                        }}
                    />
                </CardActionArea>
            </Card>
            <Typography color="text.accent" sx={{ fontSize: 15.7, fontWeight: 500, marginTop: 1.5 }}>{currencyFormat(adItem.price)}</Typography>
            <Typography sx={{ fontSize: 16.4, fontWeight: 500, marginTop: "3px" }}>{adItem.title}</Typography>
        </Box>
    )
}

export default AdAdapter