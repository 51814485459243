import { Box, Container, Paper, Toolbar, Typography } from '@mui/material'
import { doc, getDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import firebaseDatabase from '../../firebase/firebase_db';
import AdPageSkeleton from './containers/ad_page_skeleton';
import AdPageNotFound from './containers/ad_page_notfound';
import AdPageLoaded from './containers/ad_page_loaded';
import { Helmet } from 'react-helmet';
import { currencyFormat } from '../../config/apputilty';

function AdPage() {
    const [pageLoading, setPageLoading] = useState(true);
    const [ad, setAd] = useState([]);

    const location = useLocation();

    useEffect(() => {
        loadAd();
    }, [location.search])

    async function loadAd() {
        const params = new URLSearchParams(location.search);
        const id = params.get("pid");
        const docRef = doc(firebaseDatabase, "posts", id);
        const docSnap = await getDoc(docRef);


        if (docSnap.exists) {
            const sellerId = docSnap.data().sellerId;

            const docSelRef = doc(firebaseDatabase, "users", sellerId);
            const docSelSnap = await getDoc(docSelRef);

            if (docSelSnap.exists) {

                const fetchedData = {
                    id: docSnap.id,
                    title: docSnap.data().title,
                    description: docSnap.data().description,
                    price: docSnap.data().price,
                    locationText: docSnap.data().locationText,
                    images: docSnap.data().images,
                    category: docSnap.data().category,
                    subCategory: docSnap.data().subCategory,
                    locationText: docSnap.data().locationText,
                    sellerId: docSelSnap.id,
                    sellerImage: docSelSnap.data().image,
                    sellerName: docSelSnap.data().firstName + " " + docSelSnap.data().lastName,
                    sellerPhoneNumber: docSelSnap.data().phoneNumber,
                    sellerWhatsAppNumber: docSelSnap.data().whatsappNumber,
                    isPhoneCallEnabled: docSelSnap.data().isPhoneCallEnabled,
                    isWhatsappChatEnabled: docSelSnap.data().isWhatsappChatEnabled,
                };
                setPageLoading(false);
                setAd(fetchedData);
            }

        } else {
            setPageLoading(false);
            setAd(null);
        }
    }

    return (
        <Container>
            <Helmet>
                <title>{pageLoading !== true && ad !== null ? `${ad.title} | ${ad.subCategory} in ${ad.locationText.split(",")[0]}` : "Buy, sell or exchange anywhere in India | Kabejo"}</title>
                <meta name="description" key="description" content={pageLoading !== true && ad !== null ? `${ad.description}` : "Buy, sell or exchange Mobile phones, Bikes, Cars, Laptops,TV and much more. Start posting an Ad today!"} />
                <meta property="og:title" key="og:title" content={`${ad.title} for ${currencyFormat(ad.price)} | Kabejo`} />
                <meta property="og:description" key="og:description" content={ad.description} />
                <meta property="og:url" key="og:url" content={`https://kabejo.com/post?pid=${ad.id}`} />
                <meta property="og:locale" key="og:locale" content="en_IN" />
                <meta property="og:type" key="og:type" content="website" />
            </Helmet>
            <Toolbar />
            {pageLoading !== true ? ad !== null ? <AdPageLoaded ad={ad} /> : <AdPageNotFound /> : <AdPageSkeleton />}
        </Container>
    )
}

export default AdPage