import {
    Typography,
} from "@mui/material";

const SectionTitle = ({ text }) => {
    return (
        <Typography sx={{ fontSize: { xs: 24, sm: 24, md: 28 }, fontFamily: "DM Serif Text" }}>{text}</Typography>
    )
}

export default SectionTitle