import React from 'react'
import UnderPriceListAdapter from '../components/adapters/under_price_list_adapter';
import { ImageList, Box } from '@mui/material';

const adList = [
    <UnderPriceListAdapter color="#5675ba" price="2000" />,
    <UnderPriceListAdapter color="#e38e64" price="5000" />,
    <UnderPriceListAdapter color="#db67ba" price="10000" />,
    <UnderPriceListAdapter color="#43c4a8" price="25000" />,
    <UnderPriceListAdapter color="#48c242" price="50000" />,
    <UnderPriceListAdapter color="#e6d819" price="100000" />,
    <UnderPriceListAdapter color="#de3a3a" price="300000" />
];

const UnderPriceListContainer = () => {
    return (
        <Box sx={{ marginTop: 3, marginBottom: 6 }}>
            <ImageList sx={{
                gridAutoFlow: "column",
                gridTemplateColumns: "repeat(auto-fill,minmax(125px,1fr)) !important",
                gridAutoColumns: "minmax(125px, 1fr)",
                marginTop: 3,
                marginBottom: 4
            }} gap={20}>
                {adList}
            </ImageList>
        </Box>
    )
}

export default UnderPriceListContainer