import { ImageList, Box } from '@mui/material'
import React from 'react'

const HorizontalListView = ({ list }) => {
    return (
        <Box sx={{ marginTop: 3, marginBottom: 6 }}>
            <ImageList sx={{
                gridAutoFlow: "column",
                gridTemplateColumns: "repeat(auto-fill,minmax(200px,1fr)) !important",
                gridAutoColumns: "minmax(200px, 1fr)",
            }} gap={12}>
                {list}
            </ImageList>
        </Box>
    )
}

export default HorizontalListView