import { Box, Paper, Typography, Avatar, Stack } from '@mui/material'
import React from 'react'
import { currencyFormat } from '../../../config/apputilty'
import FilledButton from '../../../components/buttons/filledbutton'
import OutlinedButton from '../../../components/buttons/outlinedbutton'
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css";

function AdPageLoaded({ ad }) {

    const imagesList = ad.images.map((image, index) => {
        return (
            <Box elevation={0} component="img"
                src={image}
                alt={ad.title + " image " + index + 1}
                sx={{ height: { xs: 250, sm: 300, md: 450 }, objectFit: "contain", borderRadius: 0, }} />
        );
    });

    return (
        <Stack direction={{ sm: "column", md: "row" }} flexDirection={{ sm: "column", md: "row" }}>
            <Paper elevation={0} sx={{ display: "flex", height: { xs: 250, sm: 300, md: 450 }, minWidth: { sm: "100%", md: "50%" }, maxWidth: { sm: "100%", md: "50%" }, marginTop: { xs: 2, md: 4 } }}>
                <Box elevation={0} sx={{
                    display: "flex",
                    flexGrow: 1,
                    bgcolor: "background.carousel.background",
                    borderRadius: 0
                }}>
                    <Carousel dynamicHeight={true} width="100%" showThumbs={false} showStatus={false}>
                        {imagesList}
                    </Carousel>
                </Box>
            </Paper>
            <Box sx={{ flexGrow: 1, flexDirection: "column", marginLeft: { xs: 0, sm: 2 }, marginTop: { xs: 1, md: 4 } }}>
                <Paper sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    paddingTop: 2,
                    paddingBottom: 2,
                    paddingLeft: { xs: 1, sm: 2, md: 4 },
                    paddingRight: { xs: 1, sm: 2, md: 4 },
                }} elevation={0}>
                    <Typography sx={{ fontSize: { xs: 18, md: 22 }, fontWeight: 600 }}>{ad.title}</Typography>
                    <Typography sx={{ fontSize: { xs: 16, md: 16.7 }, fontWeight: 500, marginTop: "4px" }}>{currencyFormat(ad.price)}</Typography>
                </Paper>

                <Paper sx={{
                    paddingTop: { xs: 2, md: 4 },
                    paddingBottom: { xs: 2, md: 4 },
                    paddingLeft: { xs: 1, sm: 2, md: 4 },
                    paddingRight: { xs: 1, sm: 2, md: 4 },
                    marginTop: 1,
                }} elevation={0}>
                    <Typography color="text.accent" sx={{ fontSize: 18, fontWeight: 600 }}>Description</Typography>
                    <Typography sx={{ fontSize: 14, fontWeight: 400, marginTop: 1, whiteSpace: "pre-line" }}>{ad.description}</Typography>
                </Paper>
                <Paper sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    paddingTop: 2,
                    paddingBottom: 2,
                    paddingLeft: { xs: 1, sm: 2, md: 4 },
                    paddingRight: { xs: 1, sm: 2, md: 4 },
                    marginTop: 1,
                }} elevation={0}>
                    <Typography color="text.accent" sx={{ fontSize: 18, fontWeight: 600 }}>Seller</Typography>

                    <Stack direction="row" flexDirection="row" sx={{ marginTop: 2 }}>
                        <Avatar
                            alt={ad.sellerName.toUpperCase()}
                            src={ad.sellerImage ?? "/broken-image.jpg"}
                        />
                        <Box sx={{
                            display: "flex",
                            flexGrow: 1,
                            flexDirection: "column",
                            justifyContent: "center",
                            marginLeft: 1,
                            marginRight: 1,
                        }}>

                            <Typography sx={{ fontSize: 16, fontWeight: 500, textTransform: "capitalize" }}>{ad.sellerName}</Typography>
                            <Typography color="text.secondary" sx={{ fontSize: 14, fontWeight: 500 }}>View profile</Typography>

                        </Box>
                    </Stack>
                    <Stack direction="row" flexDirection="row" sx={{ marginTop: 2 }}>
                        <FilledButton text="Call" onClick={() => window.open(`tel:${ad.sellerPhoneNumber}`, "_blank")} sx={{ flexGrow: 1, marginRight: 1 }} />
                        <OutlinedButton text="Chat" onClick={() => window.open(`https://wa.me/${ad.sellerWhatsAppNumber}`, "_blank")} sx={{ flexGrow: 1, marginLeft: 1 }} />
                    </Stack>
                </Paper>

            </Box>
        </Stack>
    )
}

export default AdPageLoaded