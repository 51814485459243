import { Box, Container, Toolbar } from '@mui/material'
import React from 'react'
import SectionTitle from "../../components/headers/section_title";
import NearByAdsListContainer from "../../containers/explore_ads_list_container";
import TopCategoriesListContainer from "../../containers/top_categories_list_container";
import UnderPriceListContainer from '../../containers/under_price_list_container';
import DownloadAppBannerContainer from '../../containers/download_app_banner_container';
import MostAffordableAdsListContainer from '../../containers/most_affordable_ads_list_container';
import HighChangeToGetSoldAdsListContainer from '../../containers/hctgs_ads_list_container';
import TodaysExclusiveAdsListContainer from '../../containers/todays_exclusive_ads_list_container';
import ExploreAdsListContainer from '../../containers/explore_ads_list_container';
import { Helmet } from 'react-helmet';


const HomePage = () => {
    return (
        <Container>
            <Helmet>
                <title>Kabejo - Shop second hand items in India</title>
                <meta name="description" key="description" content="Kabejo is your one stop shop to buy second hand mobile, bikes, cars, laptops and much more. Turn your old gadgets into cash by selling it. Kabejo! Shop ease & secure" />

            </Helmet>
            <Toolbar />
            <Box sx={{ marginTop: 3 }}>
                {/* <SectionTitle text="Explore nearby you" />
                <NearByAdsListContainer />


                <SectionTitle text="Most affordable deals" />
                <MostAffordableAdsListContainer />
                */}

                <SectionTitle text="Todays exclusive" />
                <TodaysExclusiveAdsListContainer />

                <SectionTitle text="Categories" />
                <TopCategoriesListContainer />

                <SectionTitle text="Explore nearby you" />
                <ExploreAdsListContainer />

                <SectionTitle text="Best affordable deals" />
                <MostAffordableAdsListContainer />

                <SectionTitle text="Refine by price" />
                <UnderPriceListContainer />

                <SectionTitle text="More likely to buy" />
                <HighChangeToGetSoldAdsListContainer />

                <DownloadAppBannerContainer />
            </Box>
        </Container>
    )
}

export default HomePage