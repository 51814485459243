import {
  Icon,
  styled,
  InputBase,
  alpha,
  Box,
} from "@mui/material";
import 'material-symbols';

function SearchInput() {

  const Search = styled("div")(({ theme }) => ({
    flexGrow: 1,
    position: "relative",
    borderRadius: 6,
    border: 2,
    borderColor: "#ffffff",
    backgroundColor: alpha(theme.palette.background.searchbar, 0.7),
    "&:hover": {
      backgroundColor: alpha(theme.palette.background.searchbar, 1),
    },
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  }));

  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    width: "100%",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      [theme.breakpoints.up("sm")]: {
        width: "14ch",
        "&:focus": {
          width: "18ch",
        },
      },
    },
  }));
  return (
    <Box sx={{ flexGrow: 1, display: { xs: "none", sm: "none", md: "flex" } }}>
      <Search>
        <SearchIconWrapper>
          <Icon className="material-symbols-outlined">search</Icon>
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Search for anything"
          inputProps={{ "aria-label": "search" }}
        />
      </Search>
    </Box>

  );
}

export default SearchInput