import { Box, Paper, Typography } from '@mui/material'
import React from 'react'
import { colorHexWithAplha } from '../config/apputilty'
import DownloadIconAndroid from '../get_it_on_playstore_image.png'

const DownloadAppBannerContainer = () => {
    return (
        <Paper sx={{
            marginTop: 5,
            marginBottom: 6,
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            minHeight: "150px",
            backgroundColor: colorHexWithAplha("#5d2ad5", 0.1),
            borderRadius: 0,
            padding: 4,
            justifyContent: "center",
        }} elevation={0}>
            <Typography sx={{ fontSize: 20, fontWeight: 700, }}>Kabejo is better as an App</Typography>
            <Typography sx={{ marginTop: 1, }}>Download the app to unlock full potentials compared to limited web experience</Typography>
            <Box >
                <Box onClick={() => window.open("https://play.google.com/store/apps/details?id=com.sirome.kabejo", "_blank")} component="img" src={DownloadIconAndroid} alt='Download Kabejo from Playstore' style={{ maxWidth: 150, marginLeft: "-10px", marginTop: "12px" }} />
            </Box>
        </Paper>
    )
}

export default DownloadAppBannerContainer