import Button from '@mui/material/Button';
import './filledbutton.css';
import 'material-symbols';

const Icon = ({ icon, iconType, iconSize }) => {
    return <span className="material-symbols-outlined" style={{ fontSize: iconSize }}>{icon}</span>
}

const FilledButton = ({ text, sx, icon, iconPosition, iconType, iconSize, onClick }) => {
    return (
        <Button
            startIcon={icon !== null && iconPosition === "left" ? <Icon icon={icon} iconSize={iconSize} /> : null} endIcon={icon !== null && iconPosition === "right" ? <Icon icon={icon} iconSize={iconSize} /> : null}
            variant="contained"
            disableElevation sx={{ borderRadius: 0, ...sx }}
            onClick={onClick}>{text}</Button>
    )
}

export default FilledButton