import { List, Box, ImageList, Grid } from '@mui/material'
import { useEffect, useState } from "react";
import { collection, getDocs, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import firebaseDatabase from '../firebase/firebase_db';
import CategoryListAdapter from '../components/adapters/category_list_adapter';

function TopCategoriesListContainer() {

  var [isLoading, setLoading] = useState(false);
  var [categories, setCategories] = useState([]);

  var categories = [
    { link: "/mobile-phones", image: "https://firebasestorage.googleapis.com/v0/b/kabejo-india.appspot.com/o/Private%2FApp%20data%2FImages%2FCategory%20Images%2Fci-cellphone.png?alt=media&token=6a8a5420-637a-44d8-bfa8-eacad933bf1b", title: "Mobile phones", },
    { link: "/bikes", image: "https://firebasestorage.googleapis.com/v0/b/kabejo-india.appspot.com/o/Private%2FApp%20data%2FImages%2FCategory%20Images%2Fci-scooter.png?alt=media&token=3b9eb8fb-d0f7-458c-a258-727dfebd0978", title: "Bikes", },
    { link: "/cars", image: "https://firebasestorage.googleapis.com/v0/b/kabejo-india.appspot.com/o/Private%2FApp%20data%2FImages%2FCategory%20Images%2Fci-car.png?alt=media&token=e663a0db-729b-413f-808e-f5bfb20b6dba", title: "Cars", },
    { link: "/laptops", image: "https://firebasestorage.googleapis.com/v0/b/kabejo-india.appspot.com/o/Private%2FApp%20data%2FImages%2FCategory%20Images%2Fci-laptop.png?alt=media&token=7be273de-a66a-4d9d-8288-d6c7df63eca4", title: "Laptops", },
    { link: "/tv", image: "https://firebasestorage.googleapis.com/v0/b/kabejo-india.appspot.com/o/Private%2FApp%20data%2FImages%2FCategory%20Images%2Fci-television.png?alt=media&token=563b846f-e1b4-41f1-b667-ddca57da0343", title: "Televisons", },
    { link: "/earphones", image: "https://firebasestorage.googleapis.com/v0/b/kabejo-india.appspot.com/o/Private%2FApp%20data%2FImages%2FCategory%20Images%2Fci-earphones.png?alt=media&token=de355ba7-dd8a-4d5b-95fa-c1ef73eebb95", title: "Earphones", },
    { link: "/watches", image: "https://firebasestorage.googleapis.com/v0/b/kabejo-india.appspot.com/o/Private%2FApp%20data%2FImages%2FCategory%20Images%2Fci-watch.png?alt=media&token=faa12cfa-f178-4f0a-99b8-815dd36b16bf", title: "Watches", },
    { link: "/dogs", image: "https://firebasestorage.googleapis.com/v0/b/kabejo-india.appspot.com/o/Private%2FApp%20data%2FImages%2FCategory%20Images%2Fci-dog.png?alt=media&token=6a8b88b0-e31a-4a6e-8e81-2c599efaa03b", title: "Puppies & Dogs", },
  ];

  /*  async function loadTopCategories() {
     const categoriesCollectionReference = collection(firebaseDatabase, "categories");
     const topCategoriesCollectionQuery = query(
       categoriesCollectionReference,
       orderBy("sno")
     );
     const topCategoriesCollection = await getDocs(topCategoriesCollectionQuery);
     const fetchedCategories = topCategoriesCollection.docs.map((doc) => {
       return {
         id: doc.id,
         title: doc.data().title,
         image: doc.data().image,
         sub_categories: doc.data().subCategories,
       };
     });
 
     setCategories(fetchedCategories);
     setLoading(false);
   }
 
   useEffect(() => {
     loadTopCategories();
   }, []); */

  const categoriesList = (isLoading ? Array.from(new Array(10)) : categories).map(
    (category, index) => {
      return (
        isLoading ? <Box /> : <CategoryListAdapter key={index} categoryItem={category} />
      );
    }
  );

  return (
    <Box sx={{ marginTop: 3, marginBottom: 6 }}>
      <ImageList sx={{
        gridAutoFlow: "column",
        gridTemplateRows: "repeat(2,1fr) !important",
        gridAutoColumns: "minmax(180px,1fr)",
        gridTemplateColumns: "repeat(2,minmax(180px,1fr)) !important"
      }} gap={12}>
        {categoriesList}
      </ImageList>
    </Box>
  )
}

export default TopCategoriesListContainer