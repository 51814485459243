import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Box, Container, Paper, Toolbar, Typography, Grid } from '@mui/material'
import { Helmet } from 'react-helmet';
import { collection, getDocs, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import firebaseDatabase from '../../firebase/firebase_db';
import AdAdapter from '../../components/adapters/ad_adapter';

function CategoryDetailsPage({ linkName, categoryIdLink, subCategoryIdLink }) {
    const location = useLocation();
    var [isLoading, setLoading] = useState(true);
    var [ads, setAds] = useState([]);

    useEffect(() => {
        loadAllAds();
    }, [location.search]);


    async function loadAllAds() {
        const params = new URLSearchParams(location.search);
        const categoryId = params.get("category");
        const subCategoryId = params.get("subcategory");

        const adsCollectionReference = collection(firebaseDatabase, "posts");
        const allAdsCollectionQuery = query(
            adsCollectionReference,
            where("status", "==", 100),
            where("categoryId", "==", categoryIdLink ?? categoryId),
            where("subCategoryId", "==", subCategoryIdLink ?? subCategoryId),
            orderBy("dateCreated"),
        );

        const allAdsCollection = await getDocs(allAdsCollectionQuery);
        const fetchedAds = allAdsCollection.docs.map((doc) => {
            return {
                id: doc.id,
                title: doc.data().title,
                description: doc.data().description,
                price: doc.data().price,
                locationText: doc.data().locationText,
                images: doc.data().images,
                category: doc.data().category,
                subCategory: doc.data().subCategory,
            };
        });

        setAds(fetchedAds);
        setLoading(false);
    }

    const adList = (isLoading ? Array.from(new Array(10)) : ads).map(
        (ad, index) => {
            return (
                isLoading ? <Box /> :
                    <Grid item xs={2} sm={4} md={3} key={isLoading ? index : ad.id}>
                        <AdAdapter adItem={ad} />
                    </Grid>
            );
        }
    );

    return (
        <Container>
            <Helmet>
                <title>{isLoading !== true ?
                    linkName != null ? `${linkName} in India` :
                        ads != [] ? `${ads[0].subCategory} - ${ads[0].category} in India`
                            : "Shop by categories in India"
                    : "Shop by categories in India"}</title>
                <meta name="description" key="description" content={isLoading !== true ?
                    linkName != null ? `Exclusive second hand items in India. Shop safe & secure` :
                        ads != [] ? `Shop for ${ads[0].subCategory} from ${ads[0].category} category. Exclusive offers and deals waiting for you. Kabejo make shopping experience safe and ease`
                            : "Buy, Sell or Exchange second hand items anywhere in India only on Kabejo. The safer and faster way to sell your old gadgets or buy second hand gadgets."
                    : "Buy, Sell or Exchange second hand items anywhere in India only on Kabejo. The safer and faster way to sell your old gadgets or buy second hand gadgets."} />
            </Helmet>
            <Toolbar />
            <Box>
                <Grid
                    spacing="12px"
                    container
                    columns={{ xs: 4, sm: 12, md: 12, lg: 16 }}>{adList}</Grid>
            </Box>
        </Container>
    )
}

export default CategoryDetailsPage