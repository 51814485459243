import { Card, CardActionArea, Paper, Typography } from '@mui/material';
import React from 'react'
import { colorHexWithAplha, currencyFormat } from '../../config/apputilty';

const UnderPriceListAdapter = ({ price, color }) => {
    return (
        <Paper sx={{
            border: 1,
            borderColor: color,
            bgcolor: colorHexWithAplha(color, 0.1),
            height: 125,
            width: 125,
            borderRadius: "50%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center"
        }}>
           
                <Typography sx={{ fontWeight: 500, fontSize: 16 }}>Under</Typography>
                <Typography sx={{ fontWeight: 600, fontSize: 18 }}>{currencyFormat(price)}</Typography>
           
        </Paper>
    )
}

export default UnderPriceListAdapter