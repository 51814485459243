import AdAdapter from '../components/adapters/ad_adapter';
import { List, Box, ImageList, Grid } from '@mui/material'
import { useEffect, useState } from "react";
import { collection, getDocs, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import firebaseDatabase from '../firebase/firebase_db';
import HorizontalListView from '../components/lists/horizontal_list_view';

function HighChangeToGetSoldAdsListContainer() {

  var [isLoading, setLoading] = useState(true);
  var [ads, setAds] = useState([]);

  async function loadAllAds() {
    const adsCollectionReference = collection(firebaseDatabase, "posts");
    const allAdsCollectionQuery = query(
      adsCollectionReference,
      where("status", "==", 100),
      orderBy("views", "desc"),
      orderBy("promotedAsFeatured", "desc"),
    );
    const allAdsCollection = await getDocs(allAdsCollectionQuery);
    const fetchedAds = allAdsCollection.docs.map((doc) => {
      return {
        id: doc.id,
        title: doc.data().title,
        description: doc.data().description,
        price: doc.data().price,
        locationText: doc.data().locationText,
        images: doc.data().images,
      };
    });

    setAds(fetchedAds);
    setLoading(false);
  }

  useEffect(() => {
    loadAllAds();
  }, []);

  const adList = (isLoading ? Array.from(new Array(10)) : ads).map(
    (ad, index) => {
      return (
        isLoading ? <Box /> : <AdAdapter key={index} adItem={ad} />
      );
    }
  );

  return (
    <HorizontalListView list={adList} />
  )
}

export default HighChangeToGetSoldAdsListContainer