import Logo from "../kabejo_logo.png";
import FilledButton from "./buttons/filledbutton";
import MaterialIconButton from "./buttons/iconbutton";
import {
    AppBar,
    Box,
    Stack,
    Toolbar,
    Typography
} from "@mui/material";
import SearchInput from "./inputs/search_input/search_input";



const NavBar = () => {
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="fixed" color="inherit" sx={{ backgroundColor: "backgound.appbar" }} elevation={2}>
                <Toolbar>
                    <img src={Logo} height={36} width={36} alt="Kabejo Logo" />
                    <Typography sx={{ fontWeight: 700, fontSize: 22, }}>Kabejo</Typography>
                    <Box sx={{
                        flexGrow: 1,
                        borderLeft: 1,
                        marginLeft: { xs: 1, sm: 2 },
                    }}>
                        <Stack direction="row"
                            flexDirection="row"
                            sx={{
                                marginLeft: { xs: 1, sm: 2, },
                                alignItems: "center",
                                marginRight: "12px",

                            }}>
                            <span className="material-symbols-outlined" style={{ fontSize: 20, padding: 0, }}>location_on</span>
                            <Typography sx={{ marginLeft: "3px", fontSize: 16, lineHeight: 1.7, fontWeight: 400 }}>India</Typography>
                        </Stack>
                    </Box>
                    <SearchInput />
                    <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: "row", justifyContent: 'flex-end' }}>

                        <MaterialIconButton icon="search" sx={{ marginRight: { xs: 0, sm: 1 }, display: { xs: "flex", sm: "flex", md: "none" } }} />
                        <MaterialIconButton icon="photo_camera" sx={{ display: { xs: "flex", sm: "none", } }} />
                        <MaterialIconButton icon="translate" sx={{ display: { xs: "none", sm: "none", md: "flex" } }} />
                        <MaterialIconButton icon="help" sx={{ marginRight: 1, display: { xs: "none", sm: "none", md: "flex" } }} />
                        <FilledButton text="Create Ad" sx={{ marginRight: { sm: "3px", md: 4 }, display: { xs: "none", sm: "flex", }, flexDirection: "row" }} icon="photo_camera" iconSize={24} iconPosition="left" />
                        {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" sx={{ display: { xs: "none", sm: "flex", md: "flex" } }} /> */}
                        <MaterialIconButton icon="person_add" sx={{ marginRight: 1, display: { xs: "none", sm: "none", md: "flex" } }} />
                        <MaterialIconButton icon="menu" sx={{ marginRight: "3px", display: { xs: "flex", sm: "flex", md: "none", } }} />
                    </Box>
                </Toolbar>
            </AppBar>
        </Box >
    )
}

export default NavBar